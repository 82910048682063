import React from 'react';
import Navbar from './global-components/navbar-v3';
import SliderV5 from './section-components/slider-v5';
import UssdFeatures from './section-components/ussd-f1';
import UssdTypes from './section-components/ussd-types';
import BannerV5 from './section-components/banner-v5';
import SoftwareStore from './section-components/software-store';
import PageHeader from './global-components/page-header';
import ContactForm from './section-components/contact-form';
import ContactInfo from './section-components/contact-info';
import FooterV2 from './global-components/footer-v2';

const UssdCode = () => {
    return <div>
        <Navbar />
        <SliderV5 />
        <UssdTypes/>
        <SoftwareStore  />
        <UssdFeatures/>
        <FooterV2 />
    </div>
}

export default UssdCode

