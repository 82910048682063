import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import Tab2 from './Data-bundlesv1';

class Topup extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'
        let data = sectiondata.topup
        let data1 = sectiondata.topup2

        let customclass = this.props.customclass ? this.props.customclass : ''
        
    return <div>
            <div className={ "job-listing-page "+customclass  }>
              <div className="container">
                <div className="job-position-area">
                  <div className="row justify-content-center">
                    <div className="col-xl-8">
                      <div className="section-title style-four">
                        <h2 className="title"  dangerouslySetInnerHTML={{__html: data.sectiontitle}}> 
                        </h2>
                      </div>
                      {/* single job list */}
                      { data.job.map(( item, i )=>
                          <div key={ i } className="single-job-list media">
                            <img src={ publicUrl+item.icon } alt={imgattr} />
                            <div className="media-body">
                              <h6>{ item.jobtitle }</h6>
                              <span>{ item.Text}</span>
                            </div>
                           
                          </div>
                        ) }
                     
                    </div>
                
                  </div>
                  <Tab2/>
                  <div className="row justify-content-center">
                    <div className="col-xl-8">
                      <div className="section-title style-four">
                        <h2 className="title"  dangerouslySetInnerHTML={{__html: data1.sectiontitle}}> 
                        </h2>
                      </div>
                      {/* single job list */}
                      { data1.job.map(( item, i )=>
                          <div key={ i } className="single-job-list media">
                            <img src={ publicUrl+item.icon } alt={imgattr} />
                            <div className="media-body">
                              <h6>{ item.jobtitle }</h6>
                              <span>{ item.Text}</span>
                            </div>
                          
                          </div>
                        ) }
                     
                    </div>
                
                  </div>
                </div>
               
              </div>
            </div>
            
        </div>
    }
}

export default Topup