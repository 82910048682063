import React from 'react';
import Navbar from './global-components/navbar-v3';
import BannerV4 from './section-components/banner-v4';
import Featuresv4 from './section-components/features -v4';
import PageHeader from './global-components/page-header';
import ContactForm from './section-components/contact-form';
import ContactInfo from './section-components/contact-info';
import FooterV2 from './global-components/footer-v2';
import Airtimev2 from './section-components/Airtimev2';
import Jumbotron  from './section-components/jumbotron';


const BulkAirtime = () => {
    return <div>
        <Navbar />
        <BannerV4/>
        <Featuresv4/>
        <Airtimev2 customclass="pd-top-80"/>
        <Jumbotron/>
        {/* <ContactForm />
        <ContactInfo /> */}
        <FooterV2 />
    </div>
}

export default BulkAirtime 

