import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';


class Tab extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'
        let data = sectiondata.about
        let customclass = this.props.customclass ? this.props.customclass : ''

    return <div>
              <div className="sba-pricing-area pd-top-110">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-9">
                      <div className="section-title text-center">
                        <h3 className="title1">Works Has Never Felt So Good</h3>
                        <p> Telesky Technologies works to give you the best at affordable, almost negligible prices.</p>
                      </div>
                    </div>
                  </div>
                  <div className="tab-area text-center">
                    <ul className="nav nav-tabs sb-pricing-tabs">
                      <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href="#tabs_1" 
                        >
                           <img src={publicUrl+"assets/img/price/sms.png" } className="img"  data-toggle="tab" href="#tabs_1"/>Bulk Sms</a>
                      </li>
                      
                    </ul>
                  </div>
                  <div  className="tab-content">
                    <div className="tab-pane fade show active" id="tabs_1">
                      <div className="row custom-gutters-20">
                      <div className="single-pricing style-two text-center">
                      <table>
                     <thead width="100%">
                     <tr>
    <th></th>
    <th>Basic Plan<br></br> 
      ( KSH 0-19,999)</th>
    <th>Plus Plan<br></br> (KSH 20,000-99,9999)</th>
    <th>Premium Plan<br></br> (KSH 100,000-399,999)</th>
    <th>Max Plan <br></br>( KSH 400,000 +) </th>

  </tr>
 </thead>
  <tr>
    <td >Safaricom</td>
    <td >1.00</td>
    <td >0.9</td>
    <td >0.8</td>
    <td >0.7</td>
    
  </tr>
  <tr>
    <td >Other  Kenya Telcos</td>
    <td >1</td>
    <td >1</td>
    <td >0.9</td>
    <td >0.8</td>
    
  </tr>
                     </table>
                        </div>
                        <div className="button">
                        <button type="button" class="btn btn-primary">Create account </button>
                        </div>
                       </div>
                       </div>                     
                        </div>
                       </div>
                       </div>
                       </div>
                       
    }
}
export default Tab