import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import { Link } from 'react-router-dom';
class Jumbotron extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'
        let data = sectiondata.about

    return <div>
            <div className="jumbotron">
               <div className= "row">  
           <div className="col-sm-4" >
              <p>Talk to us about volume pricing, flexible payment options, or technical assistance.</p>   
            </div> 
            <div className="col-sm-4" >   
                <Link to={ "/pricing" }>
                 <a  class="btn btn-outline-info"   href="#">View Pricing{ data.btn_text2 }</a>
           </Link>
            </div> 
            </div>
         </div>
        </div>
    }
}

export default Jumbotron