import React from 'react';
import Navbar from './global-components/navbar-v3';
import Features from './section-components/features';
import PageHeader from './global-components/page-header';
import ContactForm from './section-components/contact-form';
import WhatWeOffer from './section-components/what-we-offer';
import ContactInfo from './section-components/contact-info';
import FooterV2 from './global-components/footer-v2';
import BannerV7 from './section-components/banner-v7';
import Tab from './section-components/bulksms-pricing';
import Jumbotron  from './section-components/jumbotron';
const Bulksms = () => {
    return <div>
        <Navbar />
        <BannerV7/>
        <Features/>
        <WhatWeOffer customclass="pd-top-112"/>
       {/* <Tab /> */}
        {/* <ContactForm />
        <ContactInfo /> */}
        <Jumbotron/>
        <FooterV2 />
    </div>
}

export default Bulksms

