import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';
class UssdFeatures extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'
        let data = sectiondata.about
        let customclass = this.props.customclass ? this.props.customclass : ''
    return <div>
            <div className="hosting-type-area pd-top-120">
  <div className="container">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two hosting-tab-title text-centre">
                    <h5 className="title2">Features of Ussd Code</h5>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/others/ussd3.png"} alt="img" className="img1" />
                  </div>
                </div>    
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Customised USSD menu to suit your business needs</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>No internet connection or data required</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Fast two-way communication due to rapid exchange of data</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span> USSD codes can work across all mobile networkPhones</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Highly interactive.</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span> Can integrate with any business system using a web service</span>
                    </div>
                    <div className="riyaqas-check-list media mb-0">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Manage web apps more collaboratively</span>
                    </div>
                    </div>                
                </div>
              </div>
            </div>
          </div>
          <div className="jumbotron">
            <div className= "row">  
           <div className="col-sm-4" >
             <p>Talk to us about volume pricing, flexible payment options, or technical assistance.</p>   
            </div> 
            <div className="col-sm-4" >   
           <Link to={ "/pricing" }>
           <a  class="btn btn-outline-info"   href="#">View Pricing{ data.btn_text2 }</a>
           </Link>
           
            </div> 
            </div>
         </div>
          </div>
    
     </div>
 
       
    }
}

export default UssdFeatures