import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';


class IotFeatures extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'
        let data = sectiondata.about
        let customclass = this.props.customclass ? this.props.customclass : ''

    return <div>
      <div className="iotapplication">
      <h4>Our solutions catalogue</h4>
      <br></br>
      <p>Our end-to-end IoT development services and customized IoT solutions are focused on creating smart environments across various sectors. These primarily include:</p>
      </div>
            <div className="hosting-type-area pd-top-120">
  <div className="container">
    <div className="row">
  
      <div className="col-lg-3">
        <ul className="nav nav-tabs sb-hosting-tabs">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#d_tabs_1"><img src={publicUrl+"assets/img/icons/hospital.png"} alt="img" />Healthcare</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#d_tabs_2"><img src={publicUrl+"assets/img/icons/manufacture.png"} alt="img" />Manufacturing</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#d_tabs_3"><img src={publicUrl+"assets/img/icons/transport.png"} alt="img" />Transportation</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#d_tabs_4"><img src={publicUrl+"assets/img/icons/home.png"} alt="img" />Smart Homes</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#d_tabs_5"><img src={publicUrl+"assets/img/icons/agri.png"} alt="img" />Agriculture</a>
          </li>
        </ul>
      </div>
      <div className="col-lg-9">
        <div className="tab-content">
          <div className="tab-pane fade show active" id="d_tabs_1">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two hosting-tab-title">
                    <h4 className="title">Smart <span> Healthcare</span></h4>
                  </div>
                  <p>Safety in hospital and healthcare facilities can be greatly enhanced by tracking equipment and patients, providing information about where they have been, for how long and notify users in an asset has left the premises.</p>
                
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/others/20.png"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Technical complexities, letting you focus</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Powered by top of the line enterprise</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Manage web apps more collaboratively</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>We are committed to deliver fast</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Apps more collaboratively.</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Powered by top of the line enterprise</span>
                    </div>
                    <div className="riyaqas-check-list media mb-0">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Manage web apps more collaboratively</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="d_tabs_2">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two">
                    <h4 className="title">Manufacturing</h4>
                  </div>
                  <p>Workers and assets can be monitored by installing sensors and Smart Glasses, allowing supervisors to manage their performance, know their location and provide instructions in real time</p>
                
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/others/20.png"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Smart factory appliances</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Industrial sensors.</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>cyber-physical systems</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Powered by top of the line enterprise</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Manage web apps more collaboratively</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Powered by top of the line enterprise</span>
                    </div>
                    <div className="riyaqas-check-list media mb-0">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Manage web apps more collaboratively</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="d_tabs_3">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two">
                    <h4 className="title">Transportation</h4>
                  </div>
                <p>  By installing sensors in a loading truck, they can provide detailed information of the truck's path by measuring height, speed, humidity, road vibrations and more.
               </p>
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/others/20.png"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Smart Cargo and vehicle monitoring .</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Advanced driver behavior analysis</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Real-time video telematics</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Powered by top of the line enterprise</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Drone-powered route mapping</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>We are committed to deliver fast</span>
                    </div>
                    <div className="riyaqas-check-list media mb-0">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Manage web apps more collaboratively</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="d_tabs_4">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two">
                    <h4 className="title">Smart Homes</h4>
                  </div>
                  <p>Smart Home products with a comprehensive set of functionalities that range from being able to control locks, light switches, and cameras inside a home.</p>
                
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/others/20.png"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Apps more collaboratively.</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Powered by top of the line enterprise</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Manage web apps more collaboratively</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Technical complexities, letting you focus</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Powered by top of the line enterprise</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Manage web apps more collaboratively</span>
                    </div>
                    <div className="riyaqas-check-list media mb-0">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>We are committed to deliver fast</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="d_tabs_5">
            <div className="single-hosting-content">
              <div className="row">
                <div className="col-lg-3">
                  <div className="section-title style-two">
                    <h6 className="title"> Agriculture</h6>
                  </div>
                  <p>Our managed cloud hosting platform There are many variations passages of Lorem available, but the majority have suffered alteration in some form, by injected humour</p>
                  <p>Cloud hosting randomised words which don't look even slightly believable.</p>
                </div>
                <div className="col-lg-4">
                  <div className="thumb">
                    <img src={publicUrl+"assets/img/others/20.png"} alt="img" />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="riyaqas-check-list-wrap">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Greenhouse automation</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Smart weather stations</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Precision farming systems</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>We are committed to deliver fast</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Apps more collaboratively.</span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Powered by top of the line enterprise</span>
                    </div>
                    <div className="riyaqas-check-list media mb-0">
                      <img className="media-left" src={publicUrl+"assets/img/icons/check3.png"} alt="check" />
                      <span>Manage web apps more collaboratively</span>
                    </div>
                  </div>
                </div>
                <div className="jumbotron">
            <div className= "row">  
           <div className="col-sm-4" >
             <p>Talk to us about volume pricing, flexible payment options, or technical assistance.</p>   
            </div> 
            <div className="col-sm-4" >   
           <Link to={ "/pricing" }>
           <a  class="btn btn-outline-info"   href="#">View Pricing{ data.btn_text2 }</a>
           </Link>
           
            </div> 
            </div>
         </div>
              </div>
            </div>
          </div>
        </div>
      </div>    
    </div>
  </div>
</div>
 </div>
    }
}
export default IotFeatures 