import React from 'react';
import Navbar from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';

import Subscribe from './section-components/subscribe';
import Tab from './section-components/tab';
import FooterV2 from './global-components/footer-v2';

const PricingPage = () => {
    return <div>
        <Navbar />
       
        {/* <PageHeader headertitle="Pricing" /> */}
        
        <Tab />
        {/* <Subscribe /> */}
        <FooterV2 />
    </div>
}


export default PricingPage

