import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class UssdTypes extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'

    return <div>
            <div className="sba-featute-area">
                <div className="container">
                    <div className="row custom-gutters-16 justify-content-center">
                        <div className="col-xl-4 col-lg-9">
                            <div className="section-title style-two text-xl-left text-center">
                                <h2 className="title">{parse(sectiondata.ussdtype.sectiontitle)} <span>{sectiondata.features.sectiontitle_color}</span></h2>
                                <p>{sectiondata.features.short_description}</p>
                                <a className="read-more" href={sectiondata.features.btn_url}>{sectiondata.features.btn_text} <i className="la la-long-arrow-right"></i></a>
                            </div>
                        </div>
                        
                        <div className="col-xl-4 col-sm-6">
                            <div className="single-feature-left">
                              <div className="single-feature">
                                <div className="media">
                                  <img
                                    className="media-left"
                                    src={publicUrl+"assets/img/features/1.png"}
                                    alt="feature"
                                  />
                                  <div className="media-body">
                                    <h6>Dedicated USSD</h6>
                                    <p>
                                    These are USSD that are allocated to one client only. Businesses or organizations that need increased customization choose to use dedicated USSD. A dedicated USSD is your personal short code; no other business or service will have the same short code .
                                    </p>
                                    <br></br>
                                    <p>An example is *123#.</p>
                                  </div>
                                </div>
                              </div>
                              <div className="single-feature">
                                <div className="media border-radius-2">
                                  <img
                                    className="media-left"
                                    src={publicUrl+"assets/img/features/2.png"}
                                    alt="feature"
                                  />
                                  <div className="media-body">
                                    <h6>Shared USSD code</h6>
                                    <p>
                                    Use two-way SMS messaging to send and receive replies, follow up on responses with ease, and track who has responded and when.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-sm-6 mg-top-80">
                            <div className="single-feature-right">
                              <div className="single-feature">
                                <div className="media border-radius-3">
                                  <img
                                    className="media-left"
                                    src={publicUrl+"assets/img/features/3.png"} alt="feature"/>
                                  <div className="media-body">
                                    <h6>Shared USSD code</h6>
                                    <p>
                                    A dedicated USSD code is not free and can be very expensive to acquire and maintain one. Thus, one can acquire a USSD code and let it be shared by many organizations.
                       More often, a USSD aggregator acquires a a dedicated USSD code, shares it to many organization at lower prices compared to the dedicated USSD code.

<br></br>
<p>Example of a shared USSD code is *123*20#.</p>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                        </div>
 
                    </div>
                </div>
            </div>
        </div>
    }
}

export default  UssdTypes

