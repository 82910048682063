import React from 'react';
import Navbar from './global-components/navbar-v3';
import SliderV7 from './section-components/slider-v7';
import IotFeatures  from './section-components/iot-features';
import  Features3 from './section-components/feature-v3';
import PageHeader from './global-components/page-header';
import ContactForm from './section-components/contact-form';
import ContactInfo from './section-components/contact-info';
import FooterV2 from './global-components/footer-v2';

const IoT = () => {
    return <div>
        <Navbar />
        <SliderV7 />
        <Features3/>
        <IotFeatures  />
        <FooterV2 />
    </div>
}

export default IoT

