import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class Features3 extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'
    return <div>
            <div className="sba-featute-area">
                <div className="container">
                    <div className="row custom-gutters-16 justify-content-center">
                        <div className="col-xl-4 col-lg-9">
                            <div className="section-title style-two text-xl-left text-center">
                                <h2 className="title">{parse(sectiondata.featuresv3.sectiontitle)} <span>{sectiondata.featuresv3.sectiontitle_color}</span></h2>
                                <p>{sectiondata.featuresv3.short_description}</p>
                                <a className="read-more" href={sectiondata.features.btn_url}>{sectiondata.features.btn_text} <i className="la la-long-arrow-right"></i></a>
                            </div>
                        </div>
                        
                        <div className="col-xl-4 col-sm-6">
                            <div className="single-feature-left">
                              <div className="single-feature">
                                <div className="media">
                                  <img
                                    className="media-left"
                                    src={publicUrl+"assets/img/features/1.png"}
                                    alt="feature"
                                  />
                                  <div className="media-body">
                                    <h6>Industrial IoT</h6>
                                    <p>
                                    The Industrial Internet of Things is the convergence of smart technology that enables organizations to solve critical business problems and improve operational efficiency
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="single-feature">
                                <div className="media border-radius-2">
                                  <img
                                    className="media-left"
                                    src={publicUrl+"assets/img/features/2.png"}
                                    alt="feature"
                                  />
                                  <div className="media-body">
                                    <h6>Enterprise IoT</h6>
                                    <p>
                                    Advancement in technology that enables physical 'things' with embedded computing devices (tiny computers) to participate in business processes for reducing manual work and increasing overall business efficiency.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-sm-6 mg-top-80">
                            <div className="single-feature-right">
                              <div className="single-feature">
                                <div className="media border-radius-3">
                                  <img
                                    className="media-left"
                                    src={publicUrl+"assets/img/features/3.png"} alt="feature"/>
                                  <div className="media-body">
                                    <h6>Consumer IoT</h6>
                                    <p>
                                    This is  interconnected system of physical and digital objects, personally used by a consumer.

These interrelated devices are provided with unique identifiers (UIDs) to recognize and access various entities for many purposes.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                        </div>
 
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Features3

