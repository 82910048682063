import React, { Component } from "react";
import sectiondata from "../../data/sections.json";

class Tab extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "image";
    let data = sectiondata.about;
    let customclass = this.props.customclass ? this.props.customclass : "";

    return (
      <div>
        <div className="sba-pricing-area pd-top-150">
          <div className="container">
            {/* <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-9">
                      <div className="section-title text-center">
                        <h3 className="title1">Works Has Never Felt So Good</h3>
                        <p> Telesky Technologies works to give you the best at affordable, almost negligible prices.</p>
                      </div>
                    </div>
                  </div> */}
            <div className="tab-area text-center">
              <ul className="nav nav-tabs sb-pricing-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#tabs_1"
                  >
                    Bulk SMS
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#tabs_2">
                    Bulk Airtime
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#tabs_3">
                    Data Bundles
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#tabs_4">
                    USSD
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#tabs_5">
                    IVR
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#tabs_6">
                    IOT
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="tabs_1">
                <div className="row custom-gutters-20">
                  <div className="single-pricing style-two text-center">
                    <table>
                      <thead width="100%">
                        <tr>
                          <th></th>
                          <th>
                            Basic Plan<br></br>( KSH 0-19,999)
                          </th>
                          <th>
                            Plus Plan<br></br> (KSH 20,000-99,9999)
                          </th>
                          <th>
                            Premium Plan<br></br> (KSH 100,000-399,999)
                          </th>
                          <th>
                            Max Plan <br></br>( KSH 400,000 +){" "}
                          </th>
                        </tr>
                      </thead>
                      <tr>
                        <td>Safaricom</td>
                        <td>1.00</td>
                        <td>0.9</td>
                        <td>0.8</td>
                        <td>0.7</td>
                      </tr>
                      <tr>
                        <td>Other Kenya Telcos</td>
                        <td>1.0</td>
                        <td>1.0</td>
                        <td>0.9</td>
                        <td>0.8</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="tabs_2">
                <div className="row custom-gutters-20">
                  <div className="single-pricing style-two text-center">
                    <table width="100%">
                      <thead>
                        <tr>
                          <th>All Telcos</th>
                          <th>
                            Basic Plan <br></br>( KSH 0-599,999)
                          </th>
                          <th>
                            Premium Plan <br></br>(KSH 600,000-999,999)
                          </th>
                          <th>
                            Max Plan<br></br> ( KSH 1,200,000 +){" "}
                          </th>
                        </tr>
                      </thead>
                      <tr>
                        <td>Discounts</td>
                        <td>0%</td>
                        <td>1%</td>
                        <td>2%</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade " id="tabs_3">
                <div className="row custom-gutters-20">
                  <div className="data-bundles">
                    <h4>Kenya.</h4>
                    <p>Safaricom Data Bundles </p>
                  </div>
                  <div className="single-pricing style-two text-center">
                    <table width="100%">
                      <thead>
                        <tr>
                          <th>Validity</th>
                          <th>Bundle Package</th>
                          <th>WhatsApp</th>
                          <th>Cost in KES. </th>
                        </tr>
                      </thead>
                      <tr>
                        <td rowspan="6">1. Daily </td>
                      </tr>
                      <tr>
                        <td>7MB+7SMS</td>
                        <td>No</td>
                        <td>7.0</td>
                      </tr>
                      <tr>
                        <td>15MB+15SMS</td>
                        <td>No</td>
                        <td>12.0</td>
                      </tr>

                      <tr>
                        <td>50MB+50SMS</td>
                        <td>Free WhatsApp</td>
                        <td>25.0</td>
                      </tr>
                      <tr>
                        <td>150MB+150SMS</td>
                        <td>Free WhatsApp</td>
                        <td>55.00</td>
                      </tr>
                      <tr>
                        <td>500MB+500SMS</td>
                        <td>Free WhatsApp</td>
                        <td>105.00</td>
                      </tr>
                      <tr>
                        <td rowspan="4">2. Weekly </td>
                      </tr>
                      <tr>
                        <td>1GB</td>
                        <td>Free WhatsApp</td>
                        <td>275.0</td>
                      </tr>
                      <tr>
                        <td>3GB</td>
                        <td>Free WhatsApp</td>
                        <td>550.0</td>
                      </tr>
                      <tr></tr>
                      <tr>
                        <td rowspan="3">3. Monthly </td>
                      </tr>
                      <tr>
                        <td>2GB</td>
                        <td>Free WhatsApp</td>
                        <td>600.0</td>
                      </tr>
                      <tr>
                        <td>2GB</td>
                        <td>Free WhatsApp</td>
                        <td>600.0</td>
                      </tr>
                    </table>

                    <div className="data-bundles1">
                      <h4>Uganda.</h4>
                      <p>
                        MTN Data Bundles
                      </p>
                    </div>
                    <table className="table2">
                      <thead>
                        <tr>
                          <th>Validity</th>
                          <th>Bundle Package</th>
                          <th>Cost in KES. </th>
                        </tr>
                      </thead>
                      <tr>
                        <td rowspan="6">1. Daily </td>
                      </tr>
                      <tr>
                        <td>15MB</td>
                        <td>10.00</td>
                      </tr>
                      <tr>
                        <td>40MB</td>
                        <td>20.00</td>
                      </tr>

                      <tr>
                        <td>100MB</td>
                        <td>40.00</td>
                      </tr>
                      <tr>
                        <td>300MB</td>
                        <td>80.00</td>
                      </tr>
                      <tr>
                        <td>1GB</td>
                        <td>160.00</td>
                      </tr>
                      <tr>
                        <td rowspan="4">2. Weekly </td>
                      </tr>
                      <tr>
                        <td>500MB</td>
                        <td>160.00</td>
                      </tr>
                      <tr>
                        <td>1.5GB</td>
                        <td>320.00</td>
                      </tr>
                      <tr>
                        <td>5GB</td>
                        <td>640.00</td>
                      </tr>
                      <tr>
                        <td rowspan="8">3. Monthly </td>
                      </tr>
                      <tr>
                        <td>25MB</td>
                        <td>50.00</td>
                      </tr>
                      <tr>
                        <td>300MB</td>
                        <td>170.00</td>
                      </tr>
                      <tr>
                        <td>600MB</td>
                        <td>320.00</td>
                      </tr>
                      <tr>
                        <td>3GB</td>
                        <td>640.00</td>
                      </tr>
                      <tr>
                        <td>10GB</td>
                        <td>1500.00</td>
                      </tr>
                      <tr>
                        <td>30GB</td>
                        <td>3000.00</td>
                      </tr>
                      <tr>
                        <td>170GB</td>
                        <td>17335.00</td>
                      </tr>
                      <tr>
                        <td rowspan="4">4.Three-Months </td>
                      </tr>
                      <tr>
                        <td>2GB</td>
                        <td>900.00</td>
                      </tr>
                      <tr>
                        <td>10GB</td>
                        <td>2300.00</td>
                      </tr>
                      <tr>
                        <td>30GB</td>
                        <td>4500.00</td>
                      </tr>
                      <tr>
                        <td rowspan="3">5.Gaga Time Bundle </td>
                      </tr>
                      <tr>
                        <td>2GB</td>
                        <td>80.00</td>
                      </tr>
                      <tr>
                        <td>5GB</td>
                        <td>125.00</td>
                      </tr>
                      <tr>
                        <td rowspan="3">5.Tooti Bundle </td>
                      </tr>
                      <tr>
                        <td>60MB</td>
                        <td>20.00</td>
                      </tr>
                      <tr>
                        <td>5GB</td>
                        <td>125.00</td>
                      </tr>
                      <tr>
                        <td rowspan="3"></td>
                      </tr>
                      <tr>
                        <td>Unlimited Basic</td>
                        <td>5500.00</td>
                      </tr>
                      <tr>
                        <td>Unlimited Premium</td>
                        <td>9000.00</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <div className="tab-pane fade " id="tabs_4">
                <div className="row custom-gutters-20">
                  <div className="ussd">
                    <h4>Shared USSD Code</h4>
                  </div>
                  <div className="single-pricing style-two text-center">
                    <table width="100%">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Cost</th>
                        </tr>
                      </thead>
                      <tr>
                        <td>Setup Cost</td>
                        <td> KES 15000</td>
                      </tr>
                      <tr>
                        <td>Monthly Maintenance</td>
                        <td>KES 15,800</td>
                      </tr>
                    </table>
                    <div className="data-bundles1">
                      <h4>
                        Post-Paid costs per session (Cost is covered by the code
                        owner)
                      </h4>
                      <p>
                        A compulsory deposit of KES 20,000 is required for the
                        session cost{" "}
                      </p>
                    </div>
                    <table className="table2">
                      <thead>
                        <tr>
                          <th>Network</th>
                          <th>
                            Basic Plan <br></br>( KSH 0-19,999)
                          </th>
                          <th>
                            Plus Plan<br></br>( KSH 20,000-99,999)
                          </th>
                          <th>
                            Premium Plan <br></br>(KSH 200,000-399,999)
                          </th>
                          <th>
                            Max Plan<br></br> ( KSH 400,000 +){" "}
                          </th>
                        </tr>
                      </thead>
                      <tr>
                        <td>Safaricom</td>
                        <td>KES 1.5</td>
                        <td>KES 1.5</td>
                        <td>KES 1.25</td>
                        <td>KES 1.25</td>
                      </tr>
                    </table>
                    <div className="data-bundles1">
                      <h4>
                        Pre-Paid costs per session (Cost is covered by the end
                        user)
                      </h4>
                      <p>
                        {" "}
                        The telco deducts the cost of the session directly from
                        the end users’ airtime. We charge an amount per session{" "}
                        <br></br> as well as indicated in the table below. A
                        compulsory deposit of KES 20,000 is required for the
                        session cost
                      </p>
                    </div>
                    <table className="table2">
                      <thead>
                        <tr>
                          <th>Network</th>
                          <th>
                            Telco Charge to the <br></br>End User
                          </th>
                          <th>
                            Basic Plan <br></br>( KSH 0-19,999)
                          </th>
                          <th>
                            Plus Plan<br></br>( KSH 20,000-99,999)
                          </th>
                          <th>
                            Premium Plan <br></br>(KSH 200,000-399,999)
                          </th>
                          <th>
                            Max Plan<br></br> ( KSH 400,000 +){" "}
                          </th>
                        </tr>
                      </thead>
                      <tr>
                        <td>Safaricom</td>
                        <td>KES 1</td>
                        <td>KES 0.25</td>
                        <td>KES 0.25</td>
                        <td>KES 0.25</td>
                        <td>KES 0.25</td>
                      </tr>

                      <tr>
                        <td>Airtel</td>
                        <td>KES 3</td>
                        <td>KES 0.25</td>
                        <td>KES 0.25</td>
                        <td>KES 0.25</td>
                        <td>KES 0</td>
                      </tr>
                    </table>
                    <div className="data-bundles1">
                      <h4>Dedicated USSD Code</h4>
                      <p>Safaricom</p>
                    </div>
                    <table className="table2">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Cost</th>
                        </tr>
                      </thead>
                      <tr>
                        <td>Test Bed</td>
                        <td>KES 34,800</td>
                      </tr>
                      <tr>
                        <td>Set Up Cost</td>
                        <td>KES 160,000 </td>
                      </tr>
                      <tr>
                        <td>Pre-Paid Monthy Maintenance</td>
                        <td>KES 70,000 </td>
                      </tr>
                      <tr>
                        <td>Post-Paid Monthy Maintenance</td>
                        <td>KES 70,000 </td>
                      </tr>
                    </table>
                    <div className="data-bundles1">
                      <p>Airtel</p>
                    </div>
                    <table className="table2">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Cost</th>
                        </tr>
                      </thead>

                      <tr>
                        <td>Set Up Cost</td>
                        <td>KES 126,000 </td>
                      </tr>
                      <tr>
                        <td>Monthly Maintenance</td>
                        <td>KES 56,400 </td>
                      </tr>
                    </table>
                    <div className="data-bundles1">
                      <p>Telcom</p>
                    </div>
                    <table className="table2">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Cost</th>
                        </tr>
                      </thead>

                      <tr>
                        <td>Set Up Cost</td>
                        <td>KES 56,400 </td>
                      </tr>
                      <tr>
                        <td>Test Bed</td>
                        <td>KES 34,800</td>
                      </tr>
                      <tr>
                        <td>Deposit (One off)</td>
                        <td>KES 39,500 </td>
                      </tr>
                      <tr>
                        <td> Monthy Maintenance</td>
                        <td>KES 50,600 </td>
                      </tr>
                    </table>
                    <div className="data-bundles1">
                      <h4>
                        Post-Paid costs per session (Cost is covered by the end
                        user)
                      </h4>
                      <p>
                        {" "}
                        A compulsory deposit of KES 20,000 is required for the
                        session cost
                      </p>
                    </div>
                    <table className="table2">
                      <thead>
                        <tr>
                          <th>Network</th>

                          <th>
                            Basic Plan <br></br>( KSH 0-99,999)
                          </th>
                          <th>
                            Plus Plan<br></br>( KSH 100,000-499,999)
                          </th>
                          <th>
                            Premium Plan <br></br>(KSH 500,000-999,999)
                          </th>
                          <th>
                            Max Plan<br></br> ( KSH 1000,000 +){" "}
                          </th>
                        </tr>
                      </thead>
                      <tr>
                        <td>Safaricom</td>
                        <td>KES 1.5</td>
                        <td>KES 1.5</td>
                        <td>KES 1.25</td>
                        <td>KES 1.25</td>
                      </tr>
                      <tr>
                        <td>Airtel</td>
                        <td>KES 4.5</td>
                        <td>KES 4.5</td>
                        <td>KES 4.25</td>
                        <td>KES 4.25</td>
                      </tr>
                    </table>
                    <div className="data-bundles1">
                      <h4>
                        Pre-Paid costs per session (Cost is covered by the end
                        user)
                      </h4>
                      <p>
                        {" "}
                        A compulsory deposit of KES 20,000 is required for the
                        session cost
                      </p>
                    </div>
                    <table className="table2">
                      <thead>
                        <tr>
                          <th>Network</th>
                          <th>
                            Telco Charge to the <br></br>End User
                          </th>
                          <th>
                            Basic Plan <br></br>( KSH 0-99,999)
                          </th>
                          <th>
                            Plus Plan<br></br>( KSH 100,000-499,999)
                          </th>
                          <th>
                            Premium Plan <br></br>(KSH 500,000-999,999)
                          </th>
                          <th>
                            Max Plan<br></br> ( KSH 1000,000 +){" "}
                          </th>
                        </tr>
                      </thead>
                      <tr>
                        <td>Safaricom</td>
                        <td>KES 1</td>
                        <td>KES 0.25</td>
                        <td>KES o.25</td>
                        <td>KES 0.25</td>
                        <td>KES 1.25</td>
                      </tr>
                      <tr>
                        <td>Airtel</td>
                        <td>KES 3</td>
                        <td>KES 0.25</td>
                        <td>KES 0.25</td>
                        <td>KES 0.25</td>
                        <td>KES 0.25</td>
                      </tr>
                      <tr>
                        <td>Telcom</td>
                        <td>KES 1</td>
                        <td>KES 0.25</td>
                        <td>KES 0.25</td>
                        <td>KES 0.25</td>
                        <td>KES 0.25</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <div className="tab-pane fade " id="tabs_5">
                <div className="row custom-gutters-20">
                  <div className="data-bundles">
                    <h4>Kenya Phone Numbers.</h4>
                  </div>
                  <div className="single-pricing style-two text-center">
                    <table width="100%">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Deposit</th>
                          <th>Set Up Cost (in KES) </th>
                          <th>Monthly Maintenance (in KES) </th>
                        </tr>
                      </thead>

                      <tr>
                        <td>Regular (Safaricom and Airtel)</td>
                        <td>0</td>
                        <td> 5,000 + 16% VAT</td>
                        <td>KES 2,000 + 16% VAT</td>
                      </tr>
                      <tr>
                        <td>Toll Free (800 series) (Safaricom only)</td>
                        <td>50,000</td>
                        <td> 20,000 + 16% VAT</td>
                        <td>KES 15,000 + 16% VAT</td>
                      </tr>
                      <tr>
                        <td>Premium Rated (Safaricom only)</td>
                        <td>0</td>
                        <td> 20,000 + 16% VAT</td>
                        <td>KES 15,000 + 16% VAT</td>
                      </tr>
                    </table>
                    <div className="data-bundles1">
                      <p>Local Charges</p>
                    </div>
                    <table className="table2">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Cost per minute</th>
                        </tr>
                      </thead>

                      <tr>
                        <td>Regular Incoming</td>
                        <td>Ksh 0 </td>
                      </tr>
                      <tr>
                        <td>Regular outcoming</td>
                        <td>Ksh3.5 </td>
                      </tr>
                      <tr>
                        <td>SIP Incoming</td>
                        <td>Ksh 0.50 </td>
                      </tr>
                      <tr>
                        <td>SIP Outcoming</td>
                        <td>Ksh 0.50 </td>
                      </tr>
                      <tr>
                        <td>SIP Trunk</td>
                        <td>Ksh 0.75 </td>
                      </tr>
                      <tr>
                        <td>Toll Free Incoming</td>
                        <td>Ksh 3.50</td>
                      </tr>
                      <tr>
                        <td>Google TTS Standard</td>
                        <td> Ksh0.0005 </td>
                      </tr>
                      <tr>
                        <td>Google TTS Wavenet</td>
                        <td>Ksh0.002</td>
                      </tr>
                      <tr>
                        <td>Call Conference</td>
                        <td>Ksh 0.50 </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="tabs_6">
                <div className="row custom-gutters-20">
                  <div className="single-pricing style-two text-center">
                    <table width="100%">
                      <thead>
                        <tr>
                          <th></th>
                          <th>
                            Basic Plan <br></br>( KSH 0-99,999)
                          </th>
                          <th>
                            Plus Plan<br></br>( KSH 100,000-399,999)
                          </th>
                          <th>
                            Premium Plan <br></br>(KSH 400,000-599,999)
                          </th>
                          <th>
                            Max Plan<br></br> ( KSH 600,000 +){" "}
                          </th>
                        </tr>
                      </thead>
                      <tr>
                        <td>Cost per Message</td>
                        <td>ksh 0.50</td>
                        <td>ksh 0.40</td>
                        <td>ksh 0.30</td>
                        <td>ksh 0.20</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Tab;
