import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';


class Tab2 extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'
        let data = sectiondata.about
        let customclass = this.props.customclass ? this.props.customclass : ''

    return <div>
              <div className="sba-pricing-area pd-top-110">
                <div className="container">
                  {/* <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-9">
                      <div className="section-title text-center">
                        <h3 className="title1">Works Has Never Felt So Good</h3>
                        <p> Telesky Technologies works to give you the best at affordable, almost negligible prices.</p>
                      </div>
                    </div>
                  </div> */}
                  
                 <div className="tab-area text-center">
                    
                    <ul className="nav nav-tabs sb-pricing-tabs">
                    
                     <h3>Data Bundle pricing</h3>
                    </ul>
                  </div>
                
                  <div className="tab-content">
                 
              
            
                      <div className="row custom-gutters-20">
                     
                      <div className="single-pricing style-two text-center">
                     
                      <table width="100%">
                     <thead>
  <tr>
    <th>Validity</th>
    <th>Bundle Package</th>
    <th>WhatsApp</th>
    <th>Cost in KES. </th>
   
  </tr>
  </thead>
  <tr>
   <td rowspan="6">1. Daily </td>
    </tr>
    <tr>
    <td >7MB+7SMS</td>
    <td >No</td>
    <td >7.0</td>
    </tr>
    <tr>
    <td >15MB+15SMS</td>
    <td >No</td>
    <td >12.0</td>
    </tr>
    
    <tr>
    <td >50MB+50SMS</td>
    <td >Free WhatsApp</td>
    <td >30.0</td>
   </tr>
   <tr>
    <td >150MB+150SMS</td>
    <td >Free WhatsApp</td>
    <td >55.00</td>
   </tr>
   <tr>
    <td >500MB+500SMS</td>
    <td >Free WhatsApp</td>
    <td >106.00</td>
   </tr>
   <tr>
   <td rowspan="4">2. Weekly </td>
    </tr>
    <tr>
    <td >1GB</td>
    <td >Free WhatsApp</td>
    <td >300.0</td>
    </tr>
    <tr>
    <td >3GB</td>
    <td >Free WhatsApp</td>
    <td >550.0</td>
    </tr>
  <tr></tr>
  <tr>
   <td rowspan="3">3. Monthly </td>
    </tr>
    <tr>
    <td >2GB</td>
    <td >Free WhatsApp</td>
    <td >600.0</td>
    </tr>
    <tr>
    <td >5GB</td>
    <td >Free WhatsApp</td>
    <td >1150.0</td>
    </tr>
   
                     </table>

                   
                     
                        </div>
                       </div>
                       
                   
                       
                     
                       </div>
                       </div>
                       </div>
                       </div>
    }
}
export default Tab2