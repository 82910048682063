import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import footerdata from '../../data/footerdata.json';

class Navbar_v3 extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'logo'
        let anchor = '#'
        return (
            <div>
                <nav className="navbar navbar-area navbar-area-2 navbar-expand-lg">
                    <div className="container nav-container">
                        <div className="responsive-mobile-menu">
                            <div className="logo-wrapper mobile-logo">
                                <a href={anchor} className="logo">
                                     <img className="main-logo" src={publicUrl + 'assets/img/blackwhite.png'} alt={imgattr} />
                                    <img className="sticky-logo" src={publicUrl + 'assets/img/transparent-logo.png'} alt={imgattr} />
                                </a>
                            </div>
                            <div className="nav-right-content">
                            <ul>
                                <li>
                                  <a target="_blank" className="btn btn-radius btn-red" href="https://vas.teleskytech.com">Login</a>
                                </li>
                            </ul>
                        </div>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#Riyaqas_main_menu" 
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggle-icon">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                </span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="Riyaqas_main_menu">
                            <div className="logo-wrapper desktop-logo">
                                <a href="#" className="logo">
                                    <img className="main-logo" src={publicUrl + 'assets/img/blackwhite.png'} alt={imgattr} />
                                    <img className="sticky-logo" src={publicUrl + 'assets/img/transparent-logo.png'} alt={imgattr} />
                                </a>
                            </div>
                            <ul className="navbar-nav">
                                <li className="menu-item-has-children">
                                  <Link to="/products">Products</Link>
                                    <ul className="sub-menu">
                                        <li><Link to="/SMS">SMS</Link></li>
                                        <li><Link to="/USSD">USSD</Link></li>
                                        <li><Link to="/Bulk-Airtime">Airtime</Link></li>
                                        <li><Link to="/Data-Bundles">Data Bundles</Link></li>
                                        <li><Link to="/Voice">Voice</Link></li>
                                        <li><Link to="/Iot">IoT</Link></li>
                                        <li><Link to="/Auto-TopUp">Mpesa TopUp</Link></li>
                                    </ul>
                                </li>
                                <li>
                                  <Link to="/pricing">Pricing</Link>
                                </li>
                                <li>
                                  <Link to="/about">About</Link>
                                </li>
                                {/* <li>
                                    <Link to="/blog">Blog</Link>
                                </li> */}
                                <li>
                                    <Link to="/job-listing">Careers</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="nav-right-content">
                            <ul>
                                <li>
                                  <a target="_blank" className="btn btn-radius btn-red" href="https://vas.teleskytech.com">Login</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                
                    <div className="body-overlay" id="body-overlay"></div>
                    <div className="search-popup" id="search-popup">
                        <form action="index.html" className="search-form">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Search....."/>
                            </div>
                            <button type="submit" className="submit-btn"><i className="fa fa-search"></i></button>
                        </form>
                    </div>
            </div>
        )
    }
}


export default Navbar_v3