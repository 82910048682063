import React from 'react';
import Navbar from './global-components/navbar-v3';
import BannerV8 from './section-components/banner-v8';
import Featuresv5 from './section-components/features -v5'
import Jumbotron from './section-components/jumbotron'
import WhatWeOffer from './section-components/what-we-offerv1';
import Features from './section-components/features';
import PageHeader from './global-components/page-header';
import ContactForm from './section-components/contact-form';
import ContactInfo from './section-components/contact-info';
import VideoV4 from './section-components/video-v4';
import FooterV2 from './global-components/footer-v2';


const Voice = () => {
    return <div>
        <Navbar />
       < BannerV8/>
       <Featuresv5/>
       <WhatWeOffer customclass="pd-top-112"/>
       <VideoV4 />
   <Jumbotron/>
        {/* <ContactForm />
        <ContactInfo /> */}
        <FooterV2 />
    </div>
}

export default Voice

