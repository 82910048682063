import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import blogdata from './data/blogdata.json';
import Singleblogdata from './data/single-blogdata.json';
import HomeV5 from './components/home-v5';
import About from './components/about';
import Products from './components/services';
import Blog from './components/blog';
import BlogGrid from './components/blog-grid';
import BlogDetails from './components/blog-details';
import Contact from './components/contact';
import JobListing from './components/job-listing';
import JobDetails from './components/job-details';
import JobApply from './components/job-apply';
import team from './components/team';
import pricing from './components/pricing';
import offer from './components/offer';
import gallery from './components/gallery';
import workprocessing from './components/work-processing';
import faq from './components/faq';
import Error from './components/error';
import CommingSoon from './components/comming-soon';
import Shop from './components/shop';
import ShopGrid from './components/shop-grid';
import ShopDetails from './components/shop-details';
import Checkout from './components/checkout';
import Bulksms from './components/Bulk-Sms';
import BulkAirtime from './components/Bulk-Airtime';
import UssdCode from './components/Ussd-Codes';
import DataBundles from './components/Data-Bundles';
import Voice from './components/Voice';
import IoT from './components/Iot';
import AutoTopup from  './components/Auto-Topup';

class Root extends Component {
    render() {
        return(
            <Router>
                <HashRouter basename="/">
                <div>
                <Switch>
                    <Route exact path="/" component={HomeV5} />
                    <Route path="/about" component={About} />
                    <Route path="/products" component={Products} />
                    <Route path="/blog" render={ () => { return <Blog data={blogdata} /> }} />
                    <Route path="/blog-grid" render={ () => { return <BlogGrid data={blogdata} /> }} />
                    <Route path="/blog-details" render={ () => { return <BlogDetails data={Singleblogdata} /> } } />
                    <Route path="/contact" component={Contact} />
                    <Route path="/job-listing" component={JobListing} />
                    <Route path="/job-details" component={JobDetails} />
                    <Route path="/job-apply" component={JobApply} />
                    <Route path="/team" component={team} />
                    <Route path="/pricing" component={pricing} />
                    <Route path="/offer" component={offer} />
                    <Route path="/gallery" component={gallery} />
                    <Route path="/work-processing" component={workprocessing} />
                    <Route path="/faq" component={faq} />
                    <Route path="/error" component={Error} />
                    <Route path="/comming-soon" component={CommingSoon} />
                    <Route path="/shop" component={Shop} />
                    <Route path="/shop-grid" component={ShopGrid} />
                    <Route path="/shop-details" component={ShopDetails} />
                    <Route path="/checkout" component={Checkout} />
                    <Route path="/SMS" component={Bulksms} />
                    <Route path="/Bulk-Airtime" component={BulkAirtime} />
                    <Route path="/USSD" component={UssdCode} />
                    <Route path="/Data-Bundles" component={DataBundles} />
                    <Route path="/Voice" component={Voice} />
                    <Route path="/Iot" component={IoT} />
                    <Route path="/Auto-TopUp" component={AutoTopup} />
                </Switch>
                </div>
                </HashRouter>
            </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('riyaqas'));
