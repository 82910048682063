import React from 'react';
import Navbar from './global-components/navbar-v3';
import PageHeader from './global-components/page-header';
import BannerV5 from './section-components/banner-v5';
import VideoV2 from './section-components/video-v2';
import VideoV3 from './section-components/video-v3';
import WhatWeDo from './section-components/what-we-do';
import Ourprocess from './section-components/our-process';
import Subscribe from './section-components/subscribe';
import FooterV2 from './global-components/footer-v2';

const About = () => {
    return <div>
        <Navbar />
        {/* <PageHeader  headertitle=" It’s always about YOU at  Telesky Technologies !"/> */}
       
        <VideoV2 customclass="pd-top-120 bg-none" />
        <WhatWeDo customclass="pd-top-80"/>
        <Ourprocess customclass="pd-top-80"/>
        <VideoV3 />
    
        {/* <Subscribe /> */}
        <FooterV2 />
    </div>
}

export default About

