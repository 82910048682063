import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class Featuresv6 extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'

    return <div>
            <div className="sba-featute-area">
                <div className="container">
                    <div className="row custom-gutters-16 justify-content-center">
                        <div className="col-xl-4 col-lg-9">
                            <div className="section-title style-two text-xl-left text-center">
                                <h2 className="title">{parse(sectiondata.featuresv6.sectiontitle)} <span>{sectiondata.featuresv4.sectiontitle_color}</span></h2>
                                <p>{sectiondata.featuresv6.short_description}</p>
                                <a className="read-more" href={sectiondata.features.btn_url}>{sectiondata.featuresv4.btn_text} <i className="la la-long-arrow-right"></i></a>
                            </div>
                        </div>
                        
                        <div className="col-xl-4 col-sm-6">
                            <div className="single-feature-left">
                              <div className="single-feature">
                                <div className="media">
                                  <img
                                    className="media-left"
                                    src={publicUrl+"assets/img/features/number.png"}
                                    alt="feature"
                                  />
                                  <div className="media-body">
                                    <h6>Enter number</h6>
                                    <p>
                                    Its  Time to get started. Enter the mobile number(s)  you want to recharge. </p>
                                  </div>
                                </div>
                              </div>
                              <div className="single-feature">
                                <div className="media border-radius-2">
                                  <img
                                    className="media-left"
                                    src={publicUrl+"assets/img/features/amount.png"}
                                    alt="feature"
                                  />
                                  <div className="media-body">
                                    <h6>Choose amount</h6>
                                    <p>
                                    Next, Enter the amount you want to send 
                                    </p>
                                  </div>
                                </div>
                              </div>
                              
                              
                            </div>
                          </div>
                          <div className="col-xl-4 col-sm-6 mg-top-80">
                            <div className="single-feature-right">
                              <div className="single-feature">
                                <div className="media border-radius-3">
                                  <img
                                    className="media-left"
                                    src={publicUrl+"assets/img/features/complete.png"} alt="feature"/>
                                  <div className="media-body">
                                    <h6>Top up successful!</h6>
                                    <p>
                                    click “Send Top-Up,” and that’s it!
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="single-feature">
                                <div className="media border-radius-2">
                                  <img
                                    className="media-left"
                                    src={publicUrl+"assets/img/features/reschedule.png"}
                                    alt="feature"
                                  />
                                  <div className="media-body">
                                    <h6>Reschedule TopUp</h6>
                                    <p>
                                  You can Choose how frequent you want Airtime to be send to your saved  phonenumber.Our system  will automatically send 
                                    </p>
                                  </div>
                                </div>
                              </div>
                        </div>
          
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Featuresv6

