import React from 'react';
import Navbar from './global-components/navbar-v3';
import BannerV9 from './section-components/banner-v9';
import Featuresv6 from './section-components/features -v6';
import Databundlesv2 from './section-components/Databundlesv2';
import PageHeader from './global-components/page-header';
import ContactForm from './section-components/contact-form';
import ContactInfo from './section-components/contact-info';
import FooterV2 from './global-components/footer-v2';
import Jumbotron  from './section-components/jumbotron';


const DataBundles = () => {
    return <div>
        <Navbar />
       < BannerV9/>
       <Featuresv6/>
        <Databundlesv2 customclass="pd-top-80"/>
        <Jumbotron/>
        {/* <ContactForm />
        <ContactInfo /> */}
        <FooterV2 />
    </div>
}

export default DataBundles

